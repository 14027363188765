.FooterContainer {
    background-color: #000;
    width: 100%;
    height: 30vh;
    position: absolute;
    bottom: -30vh;
    display: flex;
    padding: 1rem 5%;
    gap: 5rem;
}

.footer-logo {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copyright {
    color: #aaa;
}

.info {
    width: 20vw;
    color: #aaa;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.info a {
    color: #aaa;
    text-decoration: none;
}

.info a:hover {
    color: #fff;
}

.contacts {
    color: #aaa;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.contacts span {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.contacts span ion-icon {
    font-size: 1.5rem;
}

.footer-btn-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.footer-btn-wrapper .footer-btn {
    width: 7rem;
    height: fit-content;
    padding: .5rem 1rem;
    border: none;
    outline: none;
    border-radius: 1rem; 
    background-color: #fff;
    transition: all .2s ease-in-out;
}

.footer-btn-wrapper .footer-btn:hover{
    background-color: #000;
    color: white;
    border: 2px solid white;
    scale: 1.05;
}

@media only screen and (max-width:500px){
    .FooterContainer{
        display: none;
    }
}

