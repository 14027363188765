.NavbarContainer {
  display: flex;
  padding: 0 5%;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  z-index: 2;
}

.logo img {
  width: 75px;
}

.links {
  display: flex;
  gap: 2rem;
}

.links .link {
  color: white;
  text-decoration: none;
  position: relative;
  font-size: 1.1rem;
}

.link::after {
  content: "";
  width: 0%;
  background-color: red;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  border-radius: 10rem;
  transition: all 0.2s ease-in-out;
}

.link:hover::after {
  width: 100%;
}

.link.active {
  content: "";
  width: 0%;
  background-color: red;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  border-radius: 10rem;
}

.links-res {
  display: none;
}

.links-res ion-icon {
  color: white;
  font-size: 1.5rem;
}

@media (max-width: 500px) {
  .logo,
  .links {
    display: none;
  }
  .links-res {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5%;
  }
  .NavbarContainer {
    position:fixed;
    border-radius: 1rem 1rem 0 0;
    bottom: 0;
    width: 100%;
    height: 10%;
  }
  .logo-res {
    width: 75px;
  }
  .links-res .link ion-icon {
    font-size: 2rem;
  }
  .links-res .link ion-icon:active {
    color: red;
  }
}
