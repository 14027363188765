@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.HomeContainer {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat';
}

.home-wrapper {
    display: flex;
    gap: 10%;
    position: relative;
}

.home-text {
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 50%;
    justify-content: center;
    height: calc(100vh - 80px);
    padding: 0 0 0 7.5%;
    color: #fff;
    font-weight: 500;
}

.home-text h1 {
    text-transform: uppercase;
    letter-spacing: 5px;
}

.home-text h2 {
    font-weight: 500;
}

.home-btns {
    display: flex;
    padding-left: 10%;
    gap: 10%;
}

.home-btns button {
    border: 2px solid #fff;
    outline: none;
    padding: .8rem 2.4rem;
    border-radius: 50rem;
    background-color: #000;
    cursor: pointer;
    color: #fff;
    transition: .2s all ease-in-out;
    font-size: 1rem;
}

.home-btns button:hover {
    background-color: #ddd;
    color: #000;
}

.home-tutorial {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 1rem;
}

.home-tutorial video {
    width: 50vw;
}

.home-tutorial h2 {
    color: #fff;
}

.home-tutorial-mobile {
    display: none;

}

@media only screen and (max-width: 500px) {
    .home-wrapper {
        display: flex;
        flex-direction: column;
    }

    .home-wrapper .home-text {
        width: 100%;
    }

    .home-wrapper .home-btns {
        display: flex;
        flex-direction: column;
        width: 80%;
        gap: 1rem;
    }

    .home-btns button {
        width: 100%;
    }

    .home-tutorial {
        display: none;
    }

    .home-tutorial-mobile {
        display: flex;
        height: calc(100vh - 80px);
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        padding: 1rem;
        margin-bottom: 12vh;
    }

    .home-tutorial-mobile video {
        width: 100%;
        border: 2px solid #000;
        border-radius: .4rem;
    }

    .home-tutorial-mobile h2 {
        color: #fff;
    }

}