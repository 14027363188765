.chat-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-body {
  background-color: #121212;
  width: 80vw;
  height: 80vh;
  border: 1px solid #f005;
  border-radius: 0.75rem;
  box-shadow: rgba(155, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  padding: 1rem;
  gap: 1rem;
}

.Rooms {
  width: 25%;
  background-color: #212121;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem 0.5rem;
  color: #ddd;
}

.Rooms .Rooms-Header{
  display: flex;
  gap: 2rem;
  padding: 0.5rem 1rem;
  border-bottom: solid #f00 2px;
  align-items: center;
  margin-bottom: 5px;
}
.Rooms-Header .backToHome{
  color: #fff;
  font-size: 1.3rem;
  display: grid;
  place-items: center;
  border-radius: .3rem;  
  cursor: pointer;
}
.tabs-container {
  height: auto;
  max-height: calc(100% - 64px);
  overflow: none;
}

.Room-tab {
  width: 100%;
  height: auto;
  background-color: #313131;
  border-radius: 0.25rem;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Room-tab .room-name {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.Room-tab .room-name .new-msg {
  width: 10px;
  height: 10px;
  background-color: #f00;
  border-radius: 50%;
  box-shadow: #ff00008f 0px 22px 70px 4px;
}

.chat-room {
  width: 75%;
  height: 100%;
  background-color: #212121;
  border-radius: 0.5rem;
}


@media only screen and (max-width:500px){
  .chat-container{
    padding: 0;
    display: block;
    position: relative;
    overflow: hidden;
    
  }
  .chat-body{
    width: 100%;
    height: 100%;
    padding: .25rem;
    border-radius: 0;
  }
  .Rooms{
    display: none;
  }
  .chat-room{
    width: 100%;
  }
}