* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --rotate-speed: 40;
    --count: 3;
    --easeInOutSine: cubic-bezier(0.37, 0, 0.63, 1);
    --easing: cubic-bezier(0.000, 0.37, 1.000, 0.63);
}


.void {
    width: 50%;
    max-width: 1024px;
    margin: auto;
    position: relative;
    aspect-ratio: 1 / 1;
}

ul:hover * {
    animation-play-state: paused;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    outline: none;
    z-index: 1;
}

li {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    animation: rotateCW calc(var(--rotate-speed) * 1s) var(--easing) infinite;
}

.card {
    width: 15vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 8px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #535062;
    animation: rotateCCW calc(var(--rotate-speed) * 1s) var(--easing) infinite;
}

span {
    text-decoration: none;
    color: unset;
}

.model-name {
    font-weight: 500;
    font-size: 1.3vw;
    line-height: 150%;
    color: #f90000;
    display: block;
}

svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

li:nth-child(2),
li:nth-child(2) .card {
    animation-delay: calc((var(--rotate-speed)/var(--count)) * -1s);
}

li:nth-child(3),
li:nth-child(3) .card {
    animation-delay: calc((var(--rotate-speed)/var(--count)) * -2s);
}

@keyframes rotateCW {
    from {
        transform: translate3d(0px, -50%, -1px) rotate(-45deg);
    }

    to {
        transform: translate3d(0px, -50%, 0px) rotate(-315deg);
    }
}

@keyframes rotateCCW {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(315deg);
    }
}

@keyframes pulseGlow {
    from {
        background-size: 60%;
    }

    to {
        background-size: 100%;
    }
}

.center-circle {
    position: absolute;
    width: 15vw;
    height: 15vw;
    aspect-ratio: 1 / 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ff0000;
    box-shadow: 0px 18px 36px -18px rgba(12, 5, 46, 0.3), 0px 30px 60px -12px rgba(12, 5, 46, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;



}

.second-circle {
    position: absolute;
    width: 40%;
    aspect-ratio: 1 / 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #F5F4FE;
    opacity: 0.5;
    box-shadow: 0px 18px 36px -18px rgba(12, 5, 46, 0.3), 0px 30px 60px -12px rgba(12, 5, 46, 0.25);
    border-radius: 50%;
}

.last-circle {
    position: absolute;
    width: 66%;
    aspect-ratio: 1 / 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #F5F4FE;
    opacity: 0.25;
    box-shadow: 0px 18px 36px -18px rgba(12, 5, 46, 0.3), 0px 30px 60px -12px rgba(12, 5, 46, 0.25);
    border-radius: 50%;
}


.center-circle-txt {

    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Montserrat';
    text-align: center;

}

.crop {
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1));
}


.mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    animation: pulseGlow 5s linear infinite alternate;
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-image: radial-gradient(100% 50% at 100% 50%,
            rgba(229, 26, 26, 0.25) 0%,
            rgba(229, 26, 26, 0.248) 11.79%,
            rgba(229, 26, 26, 0.242) 21.38%,
            rgba(229, 26, 26, 0.232) 29.12%,
            rgba(229, 26, 26, 0.22) 35.34%,
            rgba(229, 26, 26, 0.205) 40.37%,
            rgba(229, 26, 26, 0.187) 44.56%,
            rgba(229, 26, 26, 0.168) 48.24%,
            rgba(229, 26, 26, 0.147) 51.76%,
            rgba(229, 26, 26, 0.1258) 55.44%,
            rgba(229, 26, 26, 0.103704) 59.63%,
            rgba(229, 26, 26, 0.0814963) 64.66%,
            rgba(229, 26, 26, 0.0596) 70.88%, rgba(36, 26, 229, 0.038437) 78.62%, rgb a(34, 26, 229, 0.0184296) 88.21%,
            rgba(229, 26, 26, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.mask-txt {

    color: #ff0000;
    line-height: 1.5;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Montserrat';
    text-align: center;
    padding-right: 60%;


}

.mask:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    right: 0;
    display: block;
    background-image: linear-gradient(180deg,
            rgba(229, 26, 26, 0) 0%,
            #e51a1a 50%,
            rgba(229, 26, 26, 0) 100%);
}

.void-mobile {
    display: none;
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
    height: 90vh;
    overflow: auto;
    cursor: default;
}

/* 
.void-mobile .About-title h1 {
    color: #fff;
    font-weight: 500;
    font-size: 2.5rem;
}

.void-mobile .About-title {
    display: flex;
    font-family: 'Montserrat';
    width: 100%;
    flex-direction: column;

}

.void-mobile .About-title::after {
    content: '';
    background-color: #f00;
    height: 2px;
    width: 100%;
    box-shadow: rgb(25, 25, 25) 0px 20px 30px -10px;
} */
.void-mobile h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #fff;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

/* === HEADING STYLE #1 === */
.void-mobile .seven h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;

    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
}

.void-mobile .seven h1:after,
.void-mobile .seven h1:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    height: 5px;
    background-color: transparent;
}

.void-mobile .one h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
}

.void-mobile .about-card {
    display: flex;
    flex-direction: column;
    color: #F5F4FE;
    gap: .5rem;
}

.void-mobile .about-cards {
    display: flex;
    flex-direction: column;
    color: #F5F4FE;
    gap: 1rem;
}

.void-mobile .about-card-name {
    color: #f00;
    font-size: 1.8rem;
    background-color: #121212;
    transform: translateY(.5rem);
    width: fit-content;
    padding: 0 .4rem;
    border-radius: .5rem .5rem 0 0;
    border-bottom: 2px solid #f00;

}

.void-mobile .about-card-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #121212;
    padding: .4rem;
    border-radius: 0 .5rem .5rem.5rem;

}



@media screen and (max-width: 500px) {
    .void {
        display: none;
    }

    .void-mobile {
        display: flex;
    }
}