.Container404 {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 92vh ;
  text-align: center;
  overflow: none;
  padding: 5% 0;
  /* background-color: #121212; */
}

#title-404{
  color: white;
  font-size: 3.2rem;
}

.text-404{
  color: white;
}

#img-404{
  width: 50%;
}

#link-404{
  background-color: red;
  color: white;
  padding: 1rem 1.5rem;
  text-decoration: none;
  cursor: pointer;
  border-radius: .5rem;
}

@media only screen and (max-width:500px){
  .Container404{
    justify-content: center;
    gap: 10%;
  }
  #img-404{
    width: 85%;
  }
  #title-404{
    font-size: 2.5rem;
  }
}