@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.join-container {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif, "Montserrat";
}

.form-container {
  width: 25%;
  height: fit-content;
  background-color: #121212;
  border: 1px solid #555;
  border-radius: 1rem;
  color: #fff;
  padding: 1rem;
  position: relative;
}

.form-container .form-title {
  width: 100%;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  padding-top: 5%;
  flex-direction: column;
  text-align: center;
}

.form-container .form-title::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: red;
  margin-bottom: 2rem;
}

.form-container .inputField {
  display: flex;
  padding: 1rem 1rem;
  position: relative;
  color: white;
}

.form-container .input {
  padding: 0.5rem 1rem;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  box-shadow: 3px 3px 10px #000000, -1px -1px 6px #bfbfbf66,
    3px 3px 10px #000000, -1px -1px 6px #bfbfbf66, inset 3px 3px 10px #000000,
    inset -1px -1px 6px #bfbfbf66;
}

.form-container .inputField button {
  width: 100%;
  padding: 0.75rem 1rem;
  outline: none;
  border: none;
  background-color: #000000;
  color: #fff;
  font-size: 1rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.form-container .inputField button:hover {
  scale: 1.05;
}

