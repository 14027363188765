.RoomContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  width: 100%;
  height: 100%;
}

.RoomTopBar {
  width: 100%;
  padding: 1rem;
  background-color: #313131;
  display: flex;
  align-items: center;
  color: #ddd;
  justify-content: space-between;
  border-radius: 0.25rem;
  height: 60px;
}

.RoomTopBar .RoomName {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.RoomName .backToHome {
  color: #fff;
  display: none;
  font-size: 1.2rem;
  place-items: center;
  cursor: pointer;
}

.RoomTopBar .RoomBtnContainer {
  display: flex;
  gap: 1rem;
}

.RoomTopBtns {
  padding: 0.3rem;
  background-color: #515151;
  display: grid;
  border-radius: 0.3rem;
  place-content: center;
  transition: all ease-in-out 0.2s;
  border: 1px solid #515151;
}

.RoomTopBtns:hover {
  border: 1px solid #f00;
  color: red;
  background-color: #212121;
}

.RoomContainer .MessageContainer {
  width: 96.2%;
  background-color: #313131;
  position: absolute;
  bottom: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}

.MessageContainer .sendBtn {
  flex: 1;
  display: grid;
  place-content: center;
  color: white;
  font-size: 1.2rem;
  border-left: 1px solid #ddd;
  transition: 0.2s all ease-in-out;
}

.MessageContainer .sendBtn:hover {
  color: red;
} 

.chat-msgs-container {
  width: 100%;
  position: relative;
  height: calc(100% - 120px) !important;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem 0;
  overflow: auto;
  gap: 0.2rem;
}

.MessageContainer {
  height: 7%;
}

.MessageContainer .messageToSend {
  resize: none;
  width: 94%;
  padding: 1rem;
  background-color: transparent;
  height: fit-content;
  border: none;
  outline: none;
  color: #fff;
}

.chat-msgs-container::-webkit-scrollbar {
  width: 8px;
}

.chat-msgs-container::-webkit-scrollbar-track {
  background: #313131;
  /* color of the track */
  border-radius: 10px;
}

.chat-msgs-container::-webkit-scrollbar-thumb {
  background: #313131;
  /* color of the track */
  border-radius: 7px;
  border: 1px solid red;
}

.chat-msgs-container .chat-msg-rec {
  min-width: 35%;
  max-width: 50%;
  background-color: #313131;
  height: fit-content;
  padding: 1rem;
  position: relative;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0 1rem 1rem 1rem;
  color: #fff;
}

.chat-msg-rec p {
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.chat-msgs-container .chat-msg-sen {
  min-width: 35%;
  max-width: 50%;
  background-color: #313131;
  height: fit-content;
  position: relative;
  padding: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  border-radius: 1rem 0 1rem 1rem;
  color: #fff;
  border: 1px solid #f00;
}

.chat-msg-sen,
.chat-msg-rec {
  margin: 5px;
}

.chat-msg-sen p {
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.switch {
  position: relative;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 9999px;
  background-color: rgba(100, 116, 139, 0.377);
  transition: all 0.3s ease;
}

.switch:checked {
  background-color: #f00;
}

.switch::before {
  position: absolute;
  content: "";
  left: calc(1.5rem - 1.6rem);
  top: calc(1.5rem - 1.6rem);
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border: 1px solid rgba(100, 116, 139, 0.527);
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
  transition: all 0.3s ease;
}

.switch:hover::before {
  box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.15);
}

.switch:checked:hover::before {
  box-shadow: 0 0 0px 8px rgba(236, 72, 72, 0.15);
}

.switch:checked:before {
  transform: translateX(100%);
}

.chat-msg-rec .switch {
  position: absolute;
  top: 10%;
  right: 3%;
  scale: 0.7;
}

.chat-msg-sen .switch {
  position: absolute;
  top: 10%;
  right: 3%;
  scale: 0.7;
}

@media only screen and (max-width: 500px) {

  .RoomContainer {
    padding: 0;
  }

  .RoomTopBar {
    border-bottom: 1px solid #ddd;
    border-radius: 0.25rem 0.25rem 0 0;
    position: sticky;
  }

  .RoomContainer .MessageContainer {
    width: 100%;
    left: 0;
    bottom: 0;
    min-height: 59px;
    border-top: 1px solid #ddd;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .MessageContainer .messageToSend {
    width: 80%;
  }

  .chat-msgs-container .chat-msg-sen {
    max-width: 95%;
    min-width: 95%;
  }

  .chat-msgs-container .chat-msg-rec {
    max-width: 95%;
    min-width: 95%;
    height: fit-content;
  }

  .chat-msgs-container {
    gap: 1rem;
    position: absolute;
    bottom: 55px;
    max-height: calc(100% - 120px);
    height: fit-content;
  }
  .RoomName {
    display: flex;
    gap: 1.5rem;
  }
  .RoomName .backToHome {
    display: grid;
  }
}
