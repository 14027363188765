@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("Assets/Images/CrypticRoom.svg");
  background-size: cover;
  overflow-x: hidden;
  height: 100vh;
  /* height: -webkit-fill-available; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 500px) {
  body {
    background: radial-gradient(
          circle farthest-side at 0% 50%,
          #090909 23.5%,
          rgba(255, 170, 0, 0) 0
        )
        21px 30px,
      radial-gradient(
          circle farthest-side at 0% 50%,
          #ff0000 24%,
          rgba(240, 166, 17, 0) 0
        )
        19px 30px,
      linear-gradient(
          #090909 14%,
          rgba(240, 166, 17, 0) 0,
          rgba(240, 166, 17, 0) 85%,
          #090909 0
        )
        0 0,
      linear-gradient(
          150deg,
          #090909 24%,
          #ff0000 0,
          #ff0000 26%,
          rgba(240, 166, 17, 0) 0,
          rgba(240, 166, 17, 0) 74%,
          #ff0000 0,
          #ff0000 76%,
          #090909 0
        )
        0 0,
      linear-gradient(
          30deg,
          #090909 24%,
          #ff0000 0,
          #ff0000 26%,
          rgba(240, 166, 17, 0) 0,
          rgba(240, 166, 17, 0) 74%,
          #ff0000 0,
          #ff0000 76%,
          #090909 0
        )
        0 0,
      linear-gradient(90deg, #ff0000 2%, #090909 0, #090909 98%, #ff0000 0%) 0 0
        #090909;
    background-size: 40px 60px;
  }
}
